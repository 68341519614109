$p-dropdown-panel__decorator--size: 2px;

@mixin p-input__text {
  &:not(.bo-header__tenants-dropdown) {
    @include p-form__input--border;
  }
  min-width: 0;

  &.p-disabled {
    border-color: transparent !important;
    pointer-events: none !important;
    opacity: 1 !important;

    .p-dropdown-label {
      padding-left: 0 !important;
      &.p-placeholder {
        color: transparent !important;
      }
    }

    .p-dropdown-trigger {
      display: none;
    }
  }

  &:not(.p-disabled).p-focus {
    border-color: $c-element__form--focus--color !important;
    box-shadow: none;
  }
}

@mixin p-dropdown__panel {
  position: relative;
  z-index: $c-z-index--1 !important;
  box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.18);
  border-radius: $c-border-radius--none;
  margin-top: $c-space--md - $c-space--xs;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -$p-dropdown-panel__decorator--size;
    right: 0;
    left: 0;
    bottom: 0;
    height: $p-dropdown-panel__decorator--size;
    background-color: $c-color__brand--primary;
  }
}

@mixin p-dropdown__item {
  padding: $c-space--md $c-space--md + $c-space--xs;
  color: $c-element__document--color;
  border-radius: $c-border-radius--none;
  line-height: normal;

  &.p-highlight,
  &:not(.p-highlight):not(.p-disabled):hover {
    background-color: $c-color__neutral--grey--05;
    color: $c-element__document--color;
    cursor: pointer;
  }
}

%error-input {
  border-color: $c-color__state--danger !important;
}

@mixin border-error-input() {
  &.ng-touched.ng-invalid {
    @at-root app-dropdown#{&} ::ng-deep .p-dropdown,
      app-calendar#{&} ::ng-deep .p-calendar > .p-inputtext,
      app-autocomplete#{&} ::ng-deep .p-autocomplete > .p-inputtext {
      @extend %error-input;
    }
  }
}
