// Heading Materials for MediaCoach's BackOffice project
@import 'styles/config';

$heading-separator--line-weight: $c-space--xxs;

.m-heading {
  font-family: $c-font-family--accent;
  font-size: $c-font-size--xl;
  line-height: $c-font-size--xl;
  margin: $c-space--none;

  &--xs {
    font-size: $c-font-size--xs;
    line-height: $c-font-size--xs;
  }
  &--sm {
    font-size: $c-font-size--sm;
    line-height: $c-font-size--sm;
  }
  &--md {
    font-size: $c-font-size--md;
    line-height: $c-font-size--md;
  }
  &--lg {
    font-size: $c-font-size--lg;
    line-height: $c-font-size--lg;
  }
}

.m-separator {
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: $heading-separator--line-weight;
    background-color: $c-color__neutral--grey--05;
    top: calc(75% - #{calc($heading-separator--line-weight / 2)});
    margin-left: $c-space--sm;
  }
}

.m-uppercase {
  text-transform: uppercase;
}

.m-bold {
  font-weight: bold !important;
}

.m-subheading {
  font-size: $c-font-size--sm;
  font-weight: initial;
  line-height: $c-font-size--sm;
  margin: $c-space--none;
  color: $c-color__neutral--grey--40;

  &--sm {
    font-size: $c-font-size--xs;
    line-height: $c-font-size--xs;
  }

  &--md {
    font-size: $c-font-size--md;
    line-height: $c-font-size--md;
  }
}
