@import 'styles/config';
@import 'framework/elements/patterns-form';
@import './p-form-shared';

@mixin dropdown-inner-filter($_type) {
  .p-#{$_type}-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    align-items: center;

    .p-checkbox {
      order: 2;
      padding: $c-space--md 0 $c-space--md $c-space--md + $c-space--xs;
      box-sizing: content-box;
    }

    p-header {
      order: 3;
      flex-grow: 1;
      padding: 8px 0;
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      background-color: $c-color__neutral--grey--05;
      color: $c-element__document--color;
    }
  }

  .p-#{$_type}-filter-container {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid $c-color__neutral--grey--20;

    .p-#{$_type}-filter-icon {
      right: $c-space--md;
      color: $c-color__neutral--grey--60;
    }

    input.p-inputtext {
      border: 0 !important;
    }
  }
}
