.p-component.p-menu {
  .p-menuitem-content {
    background: transparent !important;
  }
  .p-menuitem-link {
    display: flex;
    align-items: center;
    background: transparent !important;

    > .p-menuitem-icon {
      display: inherit;
    }
  }
}
