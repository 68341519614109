@import './styles/config';
@import 'framework/main';
@import 'framework/themes/theme-lato';
@import 'framework/iconography/main';
@import 'styles/material/main';
@import 'styles/overwrites/p-menu';
@import 'styles/overwrites/p-tooltip';
@import 'styles/overwrites/p-form-shared';
@import 'styles/overwrites/p-dropdown-inner-filter';
@import '@angular/cdk/overlay-prebuilt.css';

ul {
  margin: 0;
  padding: 0;
}

.overflow-hidden {
  overflow: hidden;
  &--xs {
    overflow: hidden;
    @include t-from(sm) {
      overflow: initial;
    }
  }
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

[class*=' u-icon']::before {
  font-size: 1.2em;
}

.u-font-size--l {
  &,
  &::before {
    font-size: 1.3em !important;
  }
}

.u-font-size--xl {
  &,
  &::before {
    font-size: 2em !important;
  }
}

.u-opacity--30 {
  opacity: 0.3 !important;
}

.u-vertical-centered {
  vertical-align: middle;
}

.u-separator {
  height: 1px;
  width: 100%;
  margin: $c-space--md 0;
  background-color: #f0f0f0;
}

// PrimeNG Global Over-writes
input:disabled::placeholder {
  color: transparent !important;
}

.p-component {
  &.p-component {
    font-size: $c-font-size--xs;
    font-family: $c-font-family--primary;

    &:disabled {
      opacity: 1;
      padding-left: 0;

      ~ .p-datepicker-trigger {
        display: none;
      }
    }
  }
}

.p-selectbutton.p-selectbutton .p-button {
  &:first-child {
    border-top-left-radius: $c-border-radius--sm;
    border-bottom-left-radius: $c-border-radius--sm;
  }

  &:last-child {
    border-top-right-radius: $c-border-radius--sm;
    border-bottom-right-radius: $c-border-radius--sm;
  }
}

.p-inputtext.ng-touched.ng-dirty.ng-invalid {
  border-color: $c-color__state--danger !important;
}

$bo-checkbox--size: $c-size--xs;

body .p-checkbox {
  width: $bo-checkbox--size;
  height: $bo-checkbox--size;

  .p-checkbox-box.p-checkbox-box {
    width: $bo-checkbox--size;
    height: $bo-checkbox--size;

    border: $c-border-size--md solid $c-color__neutral--grey--30;
    background-color: transparent !important;

    &:not(.p-disabled):hover {
      border-color: $c-color__neutral--grey--40;
    }

    &.p-highlight.p-focus,
    &.p-highlight {
      border-color: $c-color__brand--primary;
      color: $c-color__brand--primary;
    }

    &.p-focus {
      box-shadow: none;
    }

    &.p-highlight,
    &.p-indeterminate {
      &,
      &.p-focus {
        border-color: $c-color__brand--primary;
        color: $c-color__brand--primary;
      }

      &:not(.p-disabled):hover {
        border-color: $c-color__brand--primary--hover;
        color: $c-color__brand--primary--hover;
      }
    }

    & .p-checkbox-icon,
    .p-highlight {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $c-size--xxs + 2;
      font-size: $c-font-size--xxs - 1;
      color: $c-color__brand--primary;
      transition: none;
      width: $c-size--xxs + 2;
    }
  }
}

.p-menu.p-menu-overlay,
.p-dropdown-panel.p-dropdown-panel {
  @include p-dropdown__panel;
  z-index: $c-z-index--5 !important;

  .p-menuitem,
  .p-dropdown-items .p-dropdown-item {
    @include p-dropdown__item;
  }

  @include dropdown-inner-filter('dropdown');
}

.p-menu.p-menu-overlay {
  .p-menuitem {
    padding-top: $c-space--sm;
    padding-bottom: $c-space--sm;
  }

  .p-menuitem-link {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .p-menuitem-icon {
    font-size: $c-font-size--xxs - 1;
  }
}

input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

.bo-incidence-tooltip.p-tooltip {
  max-width: $c-size--xl;

  .p-tooltip-text {
    color: $c-color__neutral--black;
    line-height: $c-size--xs + 4;
    background-color: $c-color__neutral--white;
  }
}

.bo-incidence-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $c-color__neutral--white !important;
}

mc-input-switch {
  --mc-input-switch-font-size: #{$c-font-size - 1} !important;
  --mc-input-switch__label-margin-left: #{$c-space--ml - 2} !important;
  --mc-input-switch__label-color: #{$c-color__neutral--grey--70} !important;
  --mc-input-switch__slider-background: #{$c-color__neutral--grey--05} !important;
  margin: (($c-size--xxs - 3) * 0.5) 0;
}

.p-tooltip-right .p-tooltip-arrow {
  left: 1px;
}

span.p-menuitem-icon.pi.pi-pencil.u-font-size--l {
  font-size: 15px !important;
  margin: 0.3rem 0.5rem 0.3rem 0;
  &::before {
    font-size: 15px !important;
  }
}

mcui-menu-item .mcui-menu-item {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
